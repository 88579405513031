import React, { useState, useEffect } from 'react';
import {
  InputAdornment,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useStore from 'src/store/cns';
import { balanceOf, renew } from 'src/utils/contract';
import { Token, Domain, UserRejectedSigningError } from 'src/types/Common';
import Loading from '../widget/Loading';
import TokenSelection from '../widget/TokenSelection';
import TimePeriod from '../widget/TimePeriod';
import { approveAllowance, checkAllowance } from 'src/utils/contracts/token';
import RegistrarDeployment from '../../contracts/deployments/Registrar.json';
import { getProvider } from 'src/utils/wallet';

interface Props {
  onValueChange: (expirationDate: string, renewalPeriod: number) => void;
  domain: Domain;
  expirationDate: string;
}

const DomainExpiration: React.FC<Props> = ({
  onValueChange,
  domain,
  expirationDate,
}) => {
  const wallet = useStore(state => state.wallet);
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);
  const selectedToken = useStore(state => state.selectedToken);
  const isLoggedIn = useStore(state => state.isLoggedIn);

  const [renewalCost, setRenewalCost] = useState(
    Number(selectedToken?.basePrice),
  );
  const [renewalPeriod, setRenewalPeriod] = useState(1);
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState('0');
  const setSnackbar = useStore(state => state.setSnackbar);

  const handleChange = (token: Token) => {
    if (token?.basePrice) {
      setRenewalCost(token?.basePrice * renewalPeriod);
    }
  };

  const handleIncrementYear = () => {
    setRenewalPeriod(prevPeriod => prevPeriod + 1);
    if (selectedToken?.basePrice) {
      setRenewalCost(prevCost => prevCost + selectedToken.basePrice);
    }
  };

  const handleDecrementYear = () => {
    if (renewalPeriod > 1) {
      setRenewalPeriod(prevPeriod => prevPeriod - 1);
      if (selectedToken?.basePrice) {
        setRenewalCost(prevCost => prevCost - selectedToken.basePrice);
      }
    }
  };

  const approveTokenAllowance = async () => {
    if (selectedToken && isLoggedIn && wallet) {
      try {
        setLoadingText('Approving token allowance...');
        setCallState('loading');
        const rememberAllowance = allowance;
        let newAllowance = await approveAllowance(
          renewalCost,
          selectedToken.tokenAddress,
        );

        while (newAllowance === rememberAllowance) {
          newAllowance = await checkAllowance(
            wallet?.address,
            RegistrarDeployment.columbus.address,
            selectedToken?.tokenAddress,
          );
        }

        setAllowance(newAllowance);
      } catch (error) {
        if (error instanceof UserRejectedSigningError) {
          setSnackbar({
            open: true,
            message: error.message,
            severity: 'error',
          });
        } else {
          console.error(error);
          setSnackbar({
            open: true,
            message: `Failed to approve token allowance.`,
            severity: 'error',
          });
        }
      }
      setCallState('idle');
    }
  };

  const renewDomain = async () => {
    if (selectedToken) {
      setLoadingText('Renewing domain...');
      setCallState('loading');
      const label = domain.name.split('.')[domain.name.split('.').length - 2];

      try {
        await renew(
          label,
          renewalPeriod,
          selectedToken.tokenAddress,
          renewalCost,
        );

        if (
          selectedToken?.tokenAddress !==
          '0x0000000000000000000000000000000000000000'
        ) {
          setAllowance(allowance - renewalCost);
        }

        setSnackbar({
          open: true,
          message: `Successfully renewed domain.`,
          severity: 'success',
        });

        onValueChange(expirationDate, renewalPeriod);
      } catch (error) {
        if (error instanceof UserRejectedSigningError) {
          setSnackbar({
            open: true,
            message: error.message,
            severity: 'error',
          });
        } else {
          console.error(error);
          setSnackbar({
            open: true,
            message: `Failed to renew domain.`,
            severity: 'error',
          });
        }
      }
      setCallState('idle');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        wallet &&
        selectedToken &&
        selectedToken?.tokenAddress !==
          '0x0000000000000000000000000000000000000000'
      ) {
        const newBalance = await balanceOf(selectedToken.tokenAddress);
        setBalance(newBalance.slice(0, newBalance.length - 18));
        setAllowance(
          await checkAllowance(
            wallet?.address,
            RegistrarDeployment.columbus.address,
            selectedToken?.tokenAddress,
          ),
        );
      } else if (
        wallet &&
        selectedToken?.tokenAddress ===
          '0x0000000000000000000000000000000000000000'
      ) {
        const provider = getProvider();
        const newBalance = (
          await provider.getBalance(wallet.address)
        ).toString();
        setBalance(newBalance.slice(0, newBalance.length - 18));
      }
    };
    fetchData();
  }, [selectedToken]);

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} rowSpacing={4} alignItems="center">
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <TextField
            data-cy="expiration-date"
            fullWidth
            label="Expiration Date"
            variant="outlined"
            value={expirationDate}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '46px',
                '&:hover fieldset': {
                  borderColor: 'grey.600',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.600',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon sx={{ color: '#9c9c9c' }} />
                </InputAdornment>
              ),
              sx: { color: '#fff' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
        </Grid>

        {domain.name.split('.').length === 2 && (
          <>
            <Grid item xs={6} display="flex" justifyContent="center">
              <TimePeriod
                timePeriod={renewalPeriod}
                handleIncrementYear={handleIncrementYear}
                handleDecrementYear={handleDecrementYear}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>ERC-20 Token</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <TokenSelection onValueChange={handleChange} />
            </Grid>

            <Grid item xs={8}>
              <Typography>Current Balance</Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              {balance} {selectedToken?.symbol}
            </Grid>

            <Grid item xs={6} display="flex" justifyContent="start">
              <Typography>Renewal Cost</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography>
                - {renewalCost} {selectedToken?.symbol}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {Number(balance) <= renewalCost ? (
              <Grid item xs={12} textAlign="center">
                <Typography>
                  Not enough {selectedToken?.name} to renew the domain.
                </Typography>
              </Grid>
            ) : (
              <>
                {callState === 'loading' ? (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={2}
                  >
                    <Loading text={loadingText} />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={8}>
                      <Typography>After Purchase Balance</Typography>
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                      {Number(balance) - renewalCost} {selectedToken?.symbol}
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="center">
                      {allowance < renewalCost &&
                      selectedToken?.tokenAddress !==
                        '0x0000000000000000000000000000000000000000' ? (
                        <Button
                          variant="contained"
                          onClick={() => approveTokenAllowance()}
                          sx={{
                            color: 'text.primary',
                            backgroundColor: 'primary.main',
                            padding: '10px 16px',
                          }}
                        >
                          Approve {renewalCost} {selectedToken?.symbol}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={renewDomain}
                          sx={{
                            color: 'text.primary',
                            backgroundColor: 'primary.main',
                            padding: '10px 16px',
                          }}
                        >
                          Renew for {renewalCost} {selectedToken?.symbol}
                        </Button>
                      )}
                    </Grid>
                    {selectedToken?.tokenAddress !==
                      '0x0000000000000000000000000000000000000000' &&
                    allowance < renewalCost ? (
                      <>
                        <Grid item xs={12} textAlign="center">
                          <Typography variant="caption">
                            You need to approve the Registrar to spend ERC-20
                            tokens on your behalf.
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default DomainExpiration;
