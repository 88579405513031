import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  FormLabel,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { CalendarToday, CopyAll } from '@mui/icons-material';
import { Chain } from 'src/types/Common';
import useStore from 'src/store/cns';
import {
  getOwner,
  getResolver,
  nameExpires,
  getCAddress,
  getAddress,
  getPubKey,
  getContentHash,
} from 'src/utils/contract';
import { deriveAddressesFromPubKey } from 'src/utils/common';

const DomainInformation = () => {
  const registerState = useStore(state => state.registerState);
  const setRegisterState = useStore(state => state.setRegisterState);
  const setFetchingNetworkData = useStore(
    state => state.setFetchingNetworkData,
  );
  const setFetchingNetworkDataText = useStore(
    state => state.setFetchingNetworkDataText,
  );

  const getOneYearFromNow = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today.toISOString().split('T')[0];
  };

  const [owner, setOwner] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState(getOneYearFromNow());

  const [domainInfo, setDomainInfo] = useState<
    { label: string; value: string }[]
  >([]);

  const [derivedAddresses, setDerivedAddresses] = useState<{
    xChainAddress: string;
    pChainAddress: string;
    cChainAddress: string;
  }>();

  const [tooltipText, setTooltipText] = useState('Click to copy');

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setTooltipText('Copied!');
    setTimeout(() => setTooltipText('Click to copy'), 1500);
  };

  useEffect(() => {
    const fetchData = async () => {
      const domainArr = registerState.domainName.split('.');

      setFetchingNetworkDataText('Loading domain information. Please wait...');
      setFetchingNetworkData(true);

      // Domain Settings
      setOwner(await getOwner(domainArr));

      // Domain Expiration
      if (domainArr.length >= 2) {
        const formattedDate = await nameExpires(
          domainArr[domainArr.length - 2],
        );
        setExpirationDate(formattedDate);
      }

      const address = await getResolver(domainArr);

      if (address.length > 0) {
        const values = [];
        // Get C-Chain Address
        const cChainAddress = await getCAddress(domainArr, address);
        if (cChainAddress.length > 0) {
          values.push({ label: 'C-Chain Address', value: cChainAddress });
        }
        // Get P-Chain Address
        const pChainAddress = await getAddress(domainArr, address, Chain.P);
        if (pChainAddress.length > 0) {
          values.push({ label: 'P-Chain Address', value: pChainAddress });
        }
        // Get X-Chain Address
        const xChainAddress = await getAddress(domainArr, address, Chain.X);
        if (pChainAddress.length > 0) {
          values.push({ label: 'X-Chain Address', value: xChainAddress });
        }
        // Get Public Key
        const pubKey = await getPubKey(domainArr, address);
        if (pubKey.length > 0) {
          values.push({ label: 'Public Key', value: pubKey });
          setDerivedAddresses(deriveAddressesFromPubKey(pubKey));
        }
        // Get Content Hash
        const contentHash = await getContentHash(domainArr, address);
        if (pubKey.length > 0) {
          values.push({ label: 'Content Hash', value: contentHash });
        }

        setDomainInfo(values);
      }

      setFetchingNetworkData(false);
      setFetchingNetworkDataText('');
    };
    setOwner('');
    setExpirationDate(getOneYearFromNow());
    setDomainInfo([]);

    fetchData();
  }, [registerState.domainName]);

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Box display="flex" justifyContent="space-between">
        <Button
          onClick={() =>
            setRegisterState({
              domainName: '',
              available: false,
              status: 'searching',
            })
          }
          sx={{
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'grey.800',
              borderColor: 'grey.600',
            },
          }}
        >
          <Typography variant="body1">Return</Typography>
        </Button>
        <Typography variant="h5">
          {registerState.domainName.split('.').map((part, index, array) => (
            <React.Fragment key={`${part}-${index}`}>
              {index < array.length - 1 ? (
                <span
                  style={{
                    marginRight: '5px',
                    color: '#66C9E8',
                  }}
                >
                  {part}
                </span>
              ) : (
                <span key={`${part}-${index}`}>.{part}</span>
              )}
              {index < array.length - 2 && '.'}
            </React.Fragment>
          ))}
        </Typography>

        <Tooltip title={tooltipText} arrow>
          <Typography
            variant="body1"
            paddingTop={0.8}
            onClick={() => handleCopyToClipboard(owner)}
            style={{ cursor: 'pointer' }}
          >
            {owner.slice(0, 6)} ... {owner.slice(-5)}
          </Typography>
        </Tooltip>
      </Box>

      <Box
        sx={{
          flexDirection: 'row',
          border: '1px solid',
          borderColor: 'grey.600',
          borderRadius: '8px',
          padding: '24px',
          height: '60vh',
          overflow: 'auto',
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="row"
          rowSpacing={5}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Expiration Date"
              variant="outlined"
              value={expirationDate}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '&:hover fieldset': {
                    borderColor: 'grey.600',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'grey.600',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarToday sx={{ color: '#9c9c9c' }} />
                  </InputAdornment>
                ),
                sx: { color: '#fff' },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          {domainInfo.map(info => (
            <Grid item xs={12} key={info.label}>
              <FormControl fullWidth>
                <FormLabel
                  sx={{
                    position: 'absolute',
                    transform: 'translateY(-24px)',
                    pointerEvents: 'none',
                  }}
                >
                  {info.label}
                </FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={info.value}
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={tooltipText} arrow>
                        <InputAdornment position="end">
                          <CopyAll
                            onClick={() => handleCopyToClipboard(info.value)}
                            style={{ cursor: 'pointer' }}
                            sx={{ color: '#9c9c9c' }}
                          />
                        </InputAdornment>
                      </Tooltip>
                    ),
                    sx: { color: '#fff' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '48px',
                      '&:hover fieldset': {
                        borderColor: 'grey.600',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'grey.600',
                      },
                    },
                  }}
                />
              </FormControl>
              {info.label === 'Public Key' && derivedAddresses ? (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="operators table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <TextField
                            fullWidth
                            label="Derived C-Chain Address"
                            variant="standard"
                            value={derivedAddresses.cChainAddress}
                            disabled
                            InputProps={{ disableUnderline: true }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={tooltipText} arrow>
                            <CopyAll
                              style={{ cursor: 'pointer' }}
                              sx={{ color: '#9c9c9c' }}
                              onClick={() =>
                                handleCopyToClipboard(
                                  derivedAddresses.cChainAddress,
                                )
                              }
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <TextField
                            fullWidth
                            label="Derived P-Chain Address"
                            variant="standard"
                            value={derivedAddresses.pChainAddress}
                            disabled
                            InputProps={{ disableUnderline: true }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={tooltipText} arrow>
                            <CopyAll
                              style={{ cursor: 'pointer' }}
                              sx={{ color: '#9c9c9c' }}
                              onClick={() =>
                                handleCopyToClipboard(
                                  derivedAddresses.pChainAddress,
                                )
                              }
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <TextField
                            fullWidth
                            label="Derived X-Chain Address"
                            variant="standard"
                            value={derivedAddresses.xChainAddress}
                            disabled
                            InputProps={{ disableUnderline: true }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title={tooltipText} arrow>
                            <CopyAll
                              style={{ cursor: 'pointer' }}
                              sx={{ color: '#9c9c9c' }}
                              onClick={() =>
                                handleCopyToClipboard(
                                  derivedAddresses.xChainAddress,
                                )
                              }
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default DomainInformation;
