import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import useStore from 'src/store/cns';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { getApprovedTokens } from 'src/utils/logs';

const Wallet = () => {
  const [privateKey, setPrivateKey] = useState<string>('');
  const login = useStore(state => state.login);
  const setTokens = useStore(state => state.setTokens);
  const setSnackbar = useStore(state => state.setSnackbar);

  const setFetchingNetworkData = useStore(
    state => state.setFetchingNetworkData,
  );
  const setFetchingNetworkDataText = useStore(
    state => state.setFetchingNetworkDataText,
  );

  const handlePrivateKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivateKey(e.target.value);
  };

  const handleLogin = async () => {
    try {
      if (privateKey) {
        setFetchingNetworkDataText('Logging in. Fetching data...');
        setFetchingNetworkData(true);
        login(new ethers.Wallet(privateKey));
        setPrivateKey('');

        setTokens(await getApprovedTokens());

        setFetchingNetworkData(false);
        setFetchingNetworkDataText('');

        navigate('/cns');
      }
    } catch (error) {
      console.error(`Failed to access wallet because of: ${error}`);
      setFetchingNetworkData(false);
      setFetchingNetworkDataText('');
      setSnackbar({
        open: true,
        message: `Failed to access wallet. Make sure to use your static private key.`,
        severity: 'error',
      });
    }
  };

  const navigate = useNavigate();

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        border: '1px solid',
        borderColor: 'rgba(145, 158, 171, 0.24)',
        borderRadius: '16px',
        padding: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Private Key
        </Typography>
        <TextField
          label="Private Key"
          variant="outlined"
          type="password"
          fullWidth
          value={privateKey}
          onChange={handlePrivateKeyChange}
          sx={{ mt: 2, mb: 4 }}
        />
        <Button
          data-cy="login-button"
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{
            color: 'text.primary',
            backgroundColor: 'primary.main',
            padding: '10px 16px',
          }}
        >
          Access Wallet
        </Button>

        <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom>
          Hint: Make sure to use your static private key.
        </Typography>
      </Box>
    </Container>
  );
};

export default Wallet;
