import React, { useState } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../widget/Loading';
import { approve, setApprovalForAll } from 'src/utils/contract';
import useStore from 'src/store/cns';
import { UserRejectedSigningError } from 'src/types/Common';

interface Props {
  resolverAddress: string;
  domain: string;
}

const RolesSettings: React.FC<Props> = ({ resolverAddress, domain }) => {
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);
  const selectedDomain = useStore(state => state.selectedDomain);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);
  const operators = useStore(state => state.operators);
  const setOperators = useStore(state => state.setOperators);

  const [operator, setOperator] = useState<string>('');
  const [manager, setManager] = useState<string>('');

  const setSnackbar = useStore(state => state.setSnackbar);

  const handleUpdate = async (
    action: string,
    funcName: (...args: any[]) => Promise<void>,
    args: any[],
  ) => {
    setLoadingText(`${action}...`);
    setCallState('loading');
    try {
      await funcName(...args);
      setSnackbar({
        open: true,
        message: `Contract call succcessfull.`,
        severity: 'success',
      });

      if (action === 'Adding Operator') {
        setOperators(operators.concat(args[0]));
      } else if (action === 'Removing Operator') {
        const newArray = [...operators];
        const index = newArray.indexOf(args[0]);
        newArray.splice(index, 1);
        setOperators(newArray);
      } else if (action === 'Adding Manager') {
        const mirrordomain = selectedDomain;
        mirrordomain?.managers?.push(args[1]);
        setSelectedDomain(mirrordomain);
      } else if (action === 'Removing Manager') {
        const mirrordomain = selectedDomain;
        const index = mirrordomain?.managers?.indexOf(args[1]);
        if (index !== undefined && index >= 0) {
          mirrordomain?.managers?.splice(index, 1);
          setSelectedDomain(mirrordomain);
        }
      }
      setManager('');
      setOperator('');
    } catch (error) {
      if (error instanceof UserRejectedSigningError) {
        setSnackbar({
          open: true,
          message: error.message,
          severity: 'error',
        });
      } else {
        console.error(error);
        setSnackbar({
          open: true,
          message: `Contract call failed.`,
          severity: 'error',
        });
      }
    }
    setCallState('idle');
  };

  return (
    <Container maxWidth="sm">
      {callState === 'loading' ? (
        <Loading text={loadingText} />
      ) : (
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Operators
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="operators table">
                  <TableBody>
                    {operators.map((operator, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {operator.slice(0, 6)} ... {operator.slice(-4)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              handleUpdate(
                                'Removing Operator',
                                setApprovalForAll,
                                [operator, false],
                              )
                            }
                          >
                            <DeleteIcon sx={{ color: 'red' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter operator address"
                value={operator}
                onChange={e => setOperator(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '46px',
                    '&:hover fieldset': {
                      borderColor: 'grey.600',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'grey.600',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  color: 'text.primary',
                  backgroundColor: 'primary.main',
                  padding: '10px 16px',
                }}
                onClick={() =>
                  handleUpdate('Adding Operator', setApprovalForAll, [
                    operator,
                    true,
                  ])
                }
              >
                Add
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 4 }}>
              <Typography variant="h6" gutterBottom>
                Managers
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="managers table">
                  <TableBody>
                    {selectedDomain?.managers?.map((manager, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {manager.slice(0, 6)} ... {manager.slice(-4)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              handleUpdate('Removing Manager', approve, [
                                domain.split('.'),
                                manager,
                                false,
                                resolverAddress,
                              ])
                            }
                          >
                            <DeleteIcon sx={{ color: 'red' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter manager address"
                value={manager}
                onChange={e => setManager(e.target.value)}
                disabled={resolverAddress.length === 0}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '46px',
                    '&:hover fieldset': {
                      borderColor: 'grey.600',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'grey.600',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  color: 'text.primary',
                  backgroundColor: 'primary.main',
                  padding: '10px 16px',
                }}
                onClick={() =>
                  handleUpdate('Adding Manager', approve, [
                    domain.split('.'),
                    manager,
                    true,
                    resolverAddress,
                  ])
                }
                disabled={resolverAddress.length === 0}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default RolesSettings;
