import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useStore from 'src/store/cns';
import RegistrarDeployment from '../../contracts/deployments/Registrar.json';
import { balanceOf, register } from 'src/utils/contract';
import { Token, UserRejectedSigningError } from 'src/types/Common';
import TokenSelection from '../widget/TokenSelection';
import TimePeriod from '../widget/TimePeriod';
import { approveAllowance, checkAllowance } from 'src/utils/contracts/token';
import Loading from '../widget/Loading';
import { insertNode } from 'src/utils/data/domains';
import { ethers } from 'ethers';
import { getProvider } from 'src/utils/wallet';

const RegisterDomain = () => {
  const wallet = useStore(state => state.wallet);
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const registerState = useStore(state => state.registerState);
  const setRegisterState = useStore(state => state.setRegisterState);
  const domainTree = useStore(state => state.domainTree);
  const setDomainTree = useStore(state => state.setDomainTree);
  const registeredDomains = useStore(state => state.registeredDomains);
  const setRegisteredDomains = useStore(state => state.setRegisteredDomains);
  const setSnackbar = useStore(state => state.setSnackbar);

  const getOneYearFromNow = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today.toISOString().split('T')[0];
  };

  const selectedToken = useStore(state => state.selectedToken);

  const [expirationDate, setExpirationDate] = useState(getOneYearFromNow());
  const [registrationPeriod, setRegistrationPeriod] = useState(1);
  const [registrationCost, setRegistrationCost] = useState(
    Number(selectedToken?.basePrice),
  );
  const [allowance, setAllowance] = useState(0);
  const [balance, setBalance] = useState('0');

  const [callState, setCallState] = useState<
    'idle' | 'approving-token' | 'registering-domain'
  >('idle');

  const handleChange = (token: Token) => {
    if (token?.basePrice) {
      setRegistrationCost(token?.basePrice * registrationPeriod);
    }
  };

  const approveTokenAllowance = async () => {
    if (selectedToken && isLoggedIn && wallet) {
      try {
        setCallState('approving-token');
        const rememberAllowance = allowance;
        let newAllowance = await approveAllowance(
          registrationCost,
          selectedToken.tokenAddress,
        );

        while (newAllowance === rememberAllowance) {
          newAllowance = await checkAllowance(
            wallet?.address,
            RegistrarDeployment.columbus.address,
            selectedToken?.tokenAddress,
          );
        }

        setAllowance(newAllowance);
      } catch (error) {
        if (error instanceof UserRejectedSigningError) {
          setSnackbar({
            open: true,
            message: error.message,
            severity: 'error',
          });
        } else {
          console.error(error);
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'error',
          });
        }
      }
      setCallState('idle');
    }
  };

  const registerDomain = async () => {
    if (selectedToken && isLoggedIn) {
      try {
        setCallState('registering-domain');
        await register(
          registerState.domainName,
          registrationPeriod,
          selectedToken.tokenAddress,
          registrationCost,
        );
        if (wallet && domainTree) {
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'success',
          });
          setDomainTree(
            insertNode(
              {
                name: registerState.domainName + '.cam',
                owner: wallet.address,
                parentHash:
                  '0xae699f70ed198095f47e32ff39f1f2a31565a531bc8a8f2dd472afdffe7a1a9d',
                hash: ethers.namehash(registerState.domainName + '.cam'),
                subdomains: [],
              },
              domainTree,
            ),
          );
          const newRegisteredDomains = registeredDomains;
          newRegisteredDomains.push({
            name: registerState.domainName + '.cam',
            owner: wallet.address,
          });
          setRegisteredDomains(newRegisteredDomains);
        } else {
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'error',
          });
        }
        setCallState('idle');
      } catch (error) {
        if (error instanceof UserRejectedSigningError) {
          setSnackbar({
            open: true,
            message: error.message,
            severity: 'error',
          });
          setCallState('idle');
        } else {
          console.error(error);
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'error',
          });
        }
      }
    }
  };

  const handleIncrementYear = () => {
    setExpirationDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() + 1);
      setRegistrationPeriod(prevPeriod => prevPeriod + 1);
      if (selectedToken?.basePrice) {
        setRegistrationCost(prevCost => prevCost + selectedToken?.basePrice);
      }
      return newDate.toISOString().split('T')[0];
    });
  };

  const handleDecrementYear = () => {
    setExpirationDate(prevDate => {
      const newDate = new Date(prevDate);
      const today = new Date();
      today.setFullYear(today.getFullYear() + 1);
      if (newDate.getFullYear() > today.getFullYear()) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        setRegistrationPeriod(prevPeriod => prevPeriod - 1);
        if (selectedToken?.basePrice) {
          setRegistrationCost(prevCost => prevCost - selectedToken?.basePrice);
        }
      }
      return newDate.toISOString().split('T')[0];
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        wallet &&
        selectedToken &&
        selectedToken?.tokenAddress !==
          '0x0000000000000000000000000000000000000000'
      ) {
        const newBalance = await balanceOf(selectedToken.tokenAddress);
        setBalance(newBalance.slice(0, newBalance.length - 18));
        setAllowance(
          await checkAllowance(
            wallet?.address,
            RegistrarDeployment.columbus.address,
            selectedToken?.tokenAddress,
          ),
        );
      } else if (
        wallet &&
        selectedToken?.tokenAddress ===
          '0x0000000000000000000000000000000000000000'
      ) {
        const provider = getProvider();
        const newBalance = (
          await provider.getBalance(wallet.address)
        ).toString();
        setBalance(newBalance.slice(0, newBalance.length - 18));
      }
    };
    fetchData();
  }, [selectedToken]);

  return (
    <Container
      data-cy="register-domain"
      maxWidth="sm"
      sx={{
        mt: 20,
        border: '1px solid',
        borderColor: 'grey.600',
        borderRadius: '8px',
        paddingX: '50px',
        paddingY: '24px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        You are about to register: {registerState.domainName}.cam
      </Typography>
      <Grid container rowSpacing={3}>
        <Grid item xs={8} display="flex" alignItems="center">
          <Typography>Expiration Date</Typography>
          <TimePeriod
            timePeriod={registrationPeriod}
            handleIncrementYear={handleIncrementYear}
            handleDecrementYear={handleDecrementYear}
          />
        </Grid>
        <Grid item xs={4}>
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              value={expirationDate}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon style={{ color: '#9c9c9c' }} />
                  </InputAdornment>
                ),
                style: { color: '#fff' },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '&:hover fieldset': {
                    borderColor: 'grey.600',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'grey.600',
                  },
                },
              }}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography>Registration Token</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <TokenSelection onValueChange={handleChange} />
        </Grid>
        <Grid item xs={8}>
          <Typography>Current Balance</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          {balance} {selectedToken?.symbol}
        </Grid>
        <Grid item xs={8}>
          <Typography>Registration Cost</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" data-cy="registration-base-price">
          <Typography>
            - {registrationCost} {selectedToken?.symbol}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {Number(balance) <= registrationCost ? (
          <Grid item xs={12} textAlign="center">
            <Typography>
              Not enough {selectedToken?.name} to purchase the domain.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={8}>
              <Typography>After Purchase Balance</Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              {Number(balance) - registrationCost} {selectedToken?.symbol}
            </Grid>
            {callState !== 'idle' ? (
              <Grid item xs={12} textAlign="center">
                {callState === 'approving-token' ? (
                  <Loading text="Approving token allowance..." />
                ) : (
                  <Loading text="Registering domain..." />
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
                  {allowance < registrationCost &&
                  selectedToken?.tokenAddress !==
                    '0x0000000000000000000000000000000000000000' ? (
                    <Button
                      variant="contained"
                      onClick={() => approveTokenAllowance()}
                      sx={{
                        color: 'text.primary',
                        backgroundColor: 'primary.main',
                        padding: '10px 16px',
                      }}
                    >
                      Approve {registrationCost} {selectedToken?.symbol}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => registerDomain()}
                      sx={{
                        color: 'text.primary',
                        backgroundColor: 'primary.main',
                        padding: '10px 16px',
                      }}
                    >
                      Register
                    </Button>
                  )}
                </Grid>
                {selectedToken?.tokenAddress !==
                  '0x0000000000000000000000000000000000000000' &&
                allowance < registrationCost ? (
                  <>
                    <Grid item xs={12} textAlign="center">
                      <Typography variant="caption">
                        You need to approve the Registrar to spend ERC-20 tokens
                        on your behalf.
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </>
            )}
            {callState === 'idle' ? (
              <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
                <Button
                  sx={{
                    color: 'text.primary',
                    borderColor: 'grey.600',
                    '&:hover': {
                      backgroundColor: 'grey.800',
                      borderColor: 'grey.600',
                    },
                  }}
                  variant="text"
                  onClick={() =>
                    setRegisterState({
                      domainName: '',
                      available: false,
                      status: 'searching',
                    })
                  }
                >
                  Cancel
                </Button>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default React.memo(RegisterDomain);
