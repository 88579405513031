import { ethers } from 'ethers';
import { labelToId } from './common';
import RegistrarABI from '../contracts/abis/Registrar.json';
import RegistryABI from '../contracts/abis/Registry.json';
import ResolverABI from '../contracts/abis/Resolver.json';
import RegistrarDeployment from '../contracts/deployments/Registrar.json';
import RegistryDeployment from '../contracts/deployments/Registry.json';
import TokenABI from '../contracts/abis/Token.json';
import { getProvider, getSigner } from './wallet';
import { Chain } from 'src/types/Common';
import { getState } from 'src/store/cns';

// Registrar
export const nameExpires = async (label: string): Promise<string> => {
  const provider = getProvider();
  const registrar = new ethers.Contract(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    provider,
  );

  const id = labelToId(label);

  const timestamp = await registrar.nameExpires(id);
  const timestampInMilliseconds = Number(timestamp) * 1000;
  const date = new Date(timestampInMilliseconds);
  const formattedDate = date.toISOString().split('T')[0];

  return formattedDate;
};

export const available = async (label: string): Promise<boolean> => {
  const provider = getProvider();
  const registrar = new ethers.Contract(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    provider,
  );

  const id = labelToId(label);

  try {
    const available = await registrar.available(id);
    return available;
  } catch (error) {
    console.log(`Could not check domain availablitly because of: ${error}`);
    return false;
  }
};

export const register = async (
  label: string,
  numberOfYears: number,
  tokenAddress: string,
  registrationCost: number,
) => {
  const wallet = getState().wallet;
  if (wallet) {
    const txCost = await estimateTxCost(
      RegistrarDeployment.columbus.address,
      RegistrarABI,
      'register',
      [label, wallet.address, tokenAddress, numberOfYears],
      registrationCost.toString(),
    );
    const signer = await getSigner(txCost, 'register');
    const address = await signer?.getAddress();

    const registrar = new ethers.Contract(
      RegistrarDeployment.columbus.address,
      RegistrarABI,
      signer,
    );
    if (tokenAddress === '0x0000000000000000000000000000000000000000') {
      await registrar.register(label, address, tokenAddress, numberOfYears, {
        value: ethers.parseEther(registrationCost.toString()),
      });
    } else {
      await registrar.register(label, address, tokenAddress, numberOfYears);
    }
  }
};

export const renew = async (
  label: string,
  renewalPeriod: number,
  tokenAddress: string,
  renewalCost: number,
) => {
  const txCost = await estimateTxCost(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    'renew',
    [label, tokenAddress, renewalPeriod],
    renewalCost.toString(),
  );
  const signer = await getSigner(txCost, 'renew');

  const registrar = new ethers.Contract(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    signer,
  );

  if (tokenAddress === '0x0000000000000000000000000000000000000000') {
    await registrar.renew(label, tokenAddress, renewalPeriod, {
      value: ethers.parseEther(renewalCost.toString()),
    });
  } else {
    await registrar.renew(label, tokenAddress, renewalPeriod);
  }
};

// Registry
export const registerSubdomain = async (
  domain: Array<string>,
  label: string,
  resolverAddress: string,
) => {
  const wallet = getState().wallet;
  const txCost = await estimateTxCost(
    RegistryDeployment.columbus.address,
    RegistryABI,
    'setSubnodeRecord',
    [domain, label, wallet?.address, resolverAddress, '86400'],
  );

  const signer = await getSigner(txCost, 'setSubnodeRecord');

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );
  if (resolverAddress.length != 0) {
    await registry.setSubnodeRecord(
      domain,
      label,
      signer,
      resolverAddress,
      '86400',
    );
  } else {
    await registry.setSubnodeOwner(domain, label, signer);
  }
};

export const setOwner = async (domain: Array<string>, newOwner: string) => {
  const txCost = await estimateTxCost(
    RegistryDeployment.columbus.address,
    RegistryABI,
    'setOwner',
    [domain, newOwner],
  );
  const signer = await getSigner(txCost, 'setOwner');

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );
  await registry.setOwner(domain, newOwner);
};

export const getOwner = async (domain: Array<string>): Promise<string> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const owner = await registry.getOwner(domain);
    return owner;
  } catch (error) {
    console.log(`Failed to query owner because of: ${error}`);
    return '';
  }
};

export const setTTL = async (domain: Array<string>, ttl: string) => {
  const txCost = await estimateTxCost(
    RegistryDeployment.columbus.address,
    RegistryABI,
    'setTTL',
    [domain, ttl],
  );
  const signer = await getSigner(txCost, 'setTTL');

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );

  await registry.setTTL(domain, ttl);
};

export const getTTL = async (domain: Array<string>): Promise<number> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const ttl = await registry.getTTL(domain);
    return ttl;
  } catch (error) {
    console.log(`Failed to query owner because of: ${error}`);
    return -1;
  }
};

export const getResolver = async (domain: Array<string>): Promise<string> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const resolver = await registry.getResolver(domain);
    if (resolver === '0x0000000000000000000000000000000000000000') {
      return '';
    }
    return resolver;
  } catch (error) {
    console.log(`Failed to query resolver because of: ${error}`);
    return '';
  }
};

export const setResolver = async (
  domain: Array<string>,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(
    RegistryDeployment.columbus.address,
    RegistryABI,
    'setResolver',
    [domain, resolverAddress],
  );
  const signer = await getSigner(txCost, 'setResolver');

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );

  await registry.setResolver(domain, resolverAddress);
};

export const setApprovalForAll = async (
  operator: string,
  approved: boolean,
) => {
  const txCost = await estimateTxCost(
    RegistryDeployment.columbus.address,
    RegistryABI,
    'setApprovalForAll',
    [operator, approved],
  );
  const signer = await getSigner(txCost, 'setApprovalForAll');

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );

  await registry.setApprovalForAll(operator, approved);
};

// Resolver
export const setCAddress = async (
  domain: Array<string>,
  address: string,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(
    resolverAddress,
    ResolverABI,
    'setAddr(string[],address)',
    [domain, address],
  );
  const signer = await getSigner(txCost, 'setAddr(string[],address)');

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  await resolver['setAddr(string[],address)'](domain, address);
};

export const getCAddress = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const cAddress = await resolver['getAddr(string[])'](domain);
    return cAddress;
  } catch (error) {
    // console.log(`Failed to query address because of: ${error}`);
    return '';
  }
};

export const setAddr = async (
  domain: Array<string>,
  address: string,
  chain: Chain,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(
    resolverAddress,
    ResolverABI,
    'setAddr(string[],string,uint8)',
    [domain, address, chain],
  );
  const signer = await getSigner(txCost, 'setAddr');

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  await resolver['setAddr(string[],string,uint8)'](domain, address, chain);
};

export const getAddress = async (
  domain: Array<string>,
  resolverAddress: string,
  chain: Chain,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const address = await resolver['getAddr(string[],uint8)'](domain, chain);
    return address;
  } catch (error) {
    console.log(`Failed to query address because of: ${error}`);
    return '';
  }
};

export const getPubKey = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const pubKey = await resolver.getPubKey(domain);
    return pubKey;
  } catch (error) {
    console.log(`Failed to query public key because of: ${error}`);
    return '';
  }
};

export const setPubKey = async (
  domain: Array<string>,
  pubKey: string,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(
    resolverAddress,
    ResolverABI,
    'setPubKey',
    [domain, pubKey],
  );
  const signer = await getSigner(txCost, 'setPubKey');

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  await resolver.setPubKey(domain, pubKey);
};

export const getContentHash = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const contentHash = await resolver.getContentHash(domain);
    return contentHash;
  } catch (error) {
    console.log(`Failed to query content hash because of: ${error}`);
    return '';
  }
};

export const setContentHash = async (
  domain: Array<string>,
  data: string,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(
    resolverAddress,
    ResolverABI,
    'setContentHash',
    [domain, data],
  );
  const signer = await getSigner(txCost, 'setContentHash');

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  await resolver.setContentHash(domain, data);
};

export const approve = async (
  domain: Array<string>,
  manager: string,
  approved: boolean,
  resolverAddress: string,
) => {
  const txCost = await estimateTxCost(resolverAddress, ResolverABI, 'approve', [
    domain,
    manager,
    approved,
  ]);
  const signer = await getSigner(txCost, 'approve');

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  await resolver.approve(domain, manager, approved);
};

// ERC-20 Token
export const mintToken = async (tokenAddress: string, amount: number) => {
  const wallet = getState().wallet;
  const txCost = await estimateTxCost(tokenAddress, TokenABI, 'mint', [
    wallet?.address,
    amount,
  ]);
  const signer = await getSigner(txCost, 'mint');

  if (signer) {
    const address = await signer?.getAddress();

    const token = new ethers.Contract(tokenAddress, TokenABI, signer);
    await token.mint(address, ethers.parseEther(amount.toString()));
  }
};

export const balanceOf = async (tokenAddress: string): Promise<string> => {
  const wallet = getState().wallet;
  const provider = getProvider();

  if (wallet) {
    const token = new ethers.Contract(tokenAddress, TokenABI, provider);
    const balance = await token.balanceOf(wallet.address);
    return balance.toString();
  }
  return '0';
};

// Helper functions
export const estimateTxCost = async (
  contractAddress: string,
  contractAbi: ethers.InterfaceAbi,
  functionName: string,
  args: any[],
  payment: string | null = null,
): Promise<any> => {
  const wallet = getState().wallet;

  if (wallet) {
    const provider = getProvider();
    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider,
    );

    const tx: ethers.TransactionLike = {
      to: contractAddress,
      from: wallet.address,
      data: contract.interface.encodeFunctionData(functionName, args),
    };

    if (payment) {
      tx.value = ethers.parseEther(payment);
    }

    const gasEstimate = await provider.estimateGas(tx);
    const gasPrice = (await provider.getFeeData()).gasPrice;

    if (gasPrice) {
      const transactionCostWei = BigInt(gasEstimate) * BigInt(gasPrice);
      const transactionCostEth = ethers.formatUnits(
        transactionCostWei,
        'ether',
      );
      return transactionCostEth;
    }
  }
};
