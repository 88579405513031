import React, { useState } from 'react';
import {
  Grid,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
} from '@mui/material';
import { Domain } from 'src/types/Common';
import { useNavigate } from 'react-router-dom';
import useStore from 'src/store/cns';

const ShowDomains = () => {
  const navigate = useNavigate();

  const domains = useStore(state => state.domains);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);

  const handleClick = (domain: Domain) => {
    setSelectedDomain(domain);
  };

  const handleEnterKey = (domain: Domain) => {
    setSelectedDomain(domain);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredDomains =
    searchQuery && domains.length > 0
      ? domains.filter(domain =>
          domain.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
        )
      : domains;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            value={searchQuery}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(event.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Tab') {
                const matchingSuggestion = filteredDomains.find(suggestion =>
                  suggestion.name.startsWith(searchQuery),
                );
                if (matchingSuggestion) {
                  e.preventDefault();
                  setSearchQuery(matchingSuggestion.name);
                }
              } else if (
                searchQuery.length !== 0 &&
                e.key === 'Enter' &&
                filteredDomains.length === 1
              ) {
                e.preventDefault();
                handleEnterKey(filteredDomains[0]);
              }
            }}
            fullWidth
            placeholder="Search domain"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '46px',
                '&:hover fieldset': {
                  borderColor: 'grey.600',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.600',
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          {filteredDomains && filteredDomains.length > 0 ? (
            <Button
              variant="contained"
              fullWidth
              sx={{
                color: 'text.primary',
                backgroundColor: 'primary.main',
                padding: '10px 16px',
              }}
              onClick={() => {
                if (searchQuery.length != 0) {
                  handleClick(filteredDomains[0]);
                }
              }}
            >
              Open Domain
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{
                color: 'text.primary',
                backgroundColor: 'primary.main',
                padding: '10px 16px',
              }}
              onClick={() => navigate('/cns')}
            >
              Register Domain
            </Button>
          )}
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, height: '60vh' }}>
        {filteredDomains && filteredDomains.length > 0 ? (
          <List sx={{ height: '75%', padding: 2, overflow: 'auto' }}>
            {filteredDomains.map((domain, index) => (
              <ListItem
                key={index}
                sx={{
                  borderBottom:
                    filteredDomains.length > 1 &&
                    index < filteredDomains.length - 1
                      ? '1px solid #475569'
                      : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(domain)}
              >
                <ListItemText
                  data-cy={`list-domain-name-${index}`}
                  primary={domain?.name}
                />
                <Typography data-cy={`list-domain-access-rights-${index}`}>
                  {domain.accessRights}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : searchQuery.length === 0 ? (
          <Typography align="center" variant="h6">
            No domains registered yet
          </Typography>
        ) : (
          <Typography align="center" variant="h6">
            You do not have {searchQuery}.cam registered yet.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(ShowDomains);
