import React from 'react';
import { CircularProgress, Typography, Backdrop, Paper } from '@mui/material';

interface LoadingProps {
  text?: string;
  open: boolean;
}

const FetchingNetworkData: React.FC<LoadingProps> = ({ text, open }) => {
  return (
    <Backdrop
      data-cy="fetching-data"
      open={open}
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
        }}
      >
        <CircularProgress color="inherit" />
        {text && (
          <Typography variant="h6" sx={{ marginTop: 2, textAlign: 'center' }}>
            {text}
          </Typography>
        )}
        <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'center' }}>
          This will only take a few seconds. Please do not close the window.
        </Typography>
      </Paper>
    </Backdrop>
  );
};

export default FetchingNetworkData;
