import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  Paper,
} from '@mui/material';
import { Token, UserRejectedSigningError } from 'src/types/Common';
import useStore from 'src/store/cns';
import { balanceOf, mintToken } from 'src/utils/contract';
import { useNavigate } from 'react-router-dom';
import CustomAlert from 'src/components/widget/CustomAlert';
import SignTransaction from 'src/components/widget/SignTransaction';

const MockTokens: React.FC = () => {
  const navigate = useNavigate();
  const tokenList = useStore(state => state.tokens);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);

  const [tokenAmount, setTokenAmount] = useState<string[][]>([]);
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tokenAddress = event.target.value;
    const token = tokenList.find(t => t.tokenAddress === tokenAddress) || null;
    setSelectedToken(token);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleMint = async () => {
    if (!selectedToken) return;

    try {
      setLoading(true);
      await mintToken(selectedToken.tokenAddress, parseInt(amount));

      setTokenAmount([]);
      const newAmounts = [];
      for (const index in tokenList) {
        if (
          tokenList[index].tokenAddress !==
          '0x0000000000000000000000000000000000000000'
        ) {
          const amount = await balanceOf(tokenList[index].tokenAddress);
          newAmounts.push([
            tokenList[index].name,
            amount.slice(0, amount.length - 18),
          ]);
        }
      }
      setTokenAmount(newAmounts);
      setSnackbar({
        open: true,
        message: `${amount} ${selectedToken.symbol} minted successfully!`,
        severity: 'success',
      });
    } catch (error) {
      if (error instanceof UserRejectedSigningError) {
        setSnackbar({
          open: true,
          message: error.message,
          severity: 'error',
        });
      } else {
        console.error(error);
        setSnackbar({
          open: true,
          message: `Minting failed`,
          severity: 'error',
        });
      }
    }

    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setTokenAmount([]);
      const newAmounts = [];
      for (const index in tokenList) {
        if (
          tokenList[index].tokenAddress !==
          '0x0000000000000000000000000000000000000000'
        ) {
          const amount = await balanceOf(tokenList[index].tokenAddress);
          newAmounts.push([
            tokenList[index].name,
            amount.slice(0, amount.length - 18),
          ]);
        }
      }
      setTokenAmount(newAmounts);
      setLoading(false);
    };
    setSelectedDomain(undefined);
    fetchData();
  }, [tokenList]);

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Button onClick={() => navigate('/cns')} sx={{ color: 'text.primary' }}>
        Return
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Mint ERC-20 Tokens
      </Typography>
      <TextField
        select
        label="Select Token"
        fullWidth
        value={selectedToken?.tokenAddress || ''}
        onChange={handleTokenChange}
        margin="normal"
        variant="outlined"
      >
        {tokenList.slice(1).map(token => (
          <MenuItem key={token.tokenAddress} value={token.tokenAddress}>
            {token.name} ({token.symbol})
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Amount"
        fullWidth
        value={amount}
        onChange={handleAmountChange}
        margin="normal"
        variant="outlined"
        type="number"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleMint}
        disabled={loading || !selectedToken || !amount}
        style={{ marginTop: '20px' }}
        sx={{
          color: 'text.primary',
          backgroundColor: 'primary.main',
          padding: '10px 16px',
        }}
      >
        {loading ? <CircularProgress size={24} /> : 'Mint Tokens'}
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token Name</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenAmount.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell align="right">{row[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
      <SignTransaction />
    </Container>
  );
};

export default MockTokens;
