import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import {
  setAddr,
  setCAddress,
  setContentHash,
  setPubKey,
  setResolver,
} from 'src/utils/contract';
import { Chain, UserRejectedSigningError } from 'src/types/Common';
import Loading from '../widget/Loading';
import useStore from 'src/store/cns';
import ResolverDeployment from '../../contracts/deployments/Resolver.json';

interface Props {
  domain: string;
}

const ResolverSettings: React.FC<Props> = ({ domain }) => {
  const [resolverInput, setResolverInput] = useState<string>('');
  const [cAddressInput, setCAddressInput] = useState<string>('');
  const [pAddressInput, setPAddressInput] = useState<string>('');
  const [xAddressInput, setXAddressInput] = useState<string>('');
  const [pubKeyInput, setPubKeyInput] = useState<string>('');
  const [contentHashInput, setContentHashInput] = useState<string>('');

  const setSnackbar = useStore(state => state.setSnackbar);
  const selectedDomain = useStore(state => state.selectedDomain);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);

  const inputs = selectedDomain?.resolver
    ? [
        {
          label: 'Resolver Address',
          prop: selectedDomain?.resolver?.resolverAddress,
          inputValue: resolverInput,
          function: setResolverInput,
          onClick: setResolver,
          args: [domain.split('.'), resolverInput],
        },
        {
          label: 'C-Chain Address',
          prop: selectedDomain?.resolver?.cChainAddress,
          inputValue: cAddressInput,
          function: setCAddressInput,
          onClick: setCAddress,
          args: [
            domain.split('.'),
            cAddressInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'X-Chain Address',
          prop: selectedDomain?.resolver?.pChainAddress,
          inputValue: pAddressInput,
          function: setPAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            xAddressInput,
            Chain.X,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'P-Chain Address',
          prop: selectedDomain?.resolver?.xChainAddress,
          inputValue: xAddressInput,
          function: setXAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            pAddressInput,
            Chain.P,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Public Key',
          prop: selectedDomain?.resolver?.publicKey,
          inputValue: pubKeyInput,
          function: setPubKeyInput,
          onClick: setPubKey,
          args: [
            domain.split('.'),
            pubKeyInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Content Hash',
          prop: selectedDomain?.resolver?.contentHash,
          inputValue: contentHashInput,
          function: setContentHashInput,
          onClick: setContentHash,
          args: [
            domain.split('.'),
            contentHashInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
      ]
    : [
        {
          label: 'Resolver Address',
          prop: '',
          inputValue: resolverInput,
          function: setResolverInput,
          onClick: setResolver,
          args: [domain.split('.'), resolverInput],
        },
        {
          label: 'C-Chain Address',
          prop: '',
          inputValue: cAddressInput,
          function: setCAddressInput,
          onClick: setCAddress,
          args: [
            domain.split('.'),
            cAddressInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'X-Chain Address',
          prop: '',
          inputValue: pAddressInput,
          function: setPAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            xAddressInput,
            Chain.X,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'P-Chain Address',
          prop: '',
          inputValue: xAddressInput,
          function: setXAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            pAddressInput,
            Chain.P,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Public Key',
          prop: '',
          inputValue: pubKeyInput,
          function: setPubKeyInput,
          onClick: setPubKey,
          args: [
            domain.split('.'),
            pubKeyInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Content Hash',
          prop: '',
          inputValue: contentHashInput,
          function: setContentHashInput,
          onClick: setContentHash,
          args: [
            domain.split('.'),
            contentHashInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
      ];

  const handleUpdate = async (
    object: string,
    funcName: (...args: any[]) => Promise<void>,
    args: any[],
  ) => {
    setLoadingText(`Updating ${object}...`);
    setCallState('loading');
    try {
      await funcName(...args);
      setSnackbar({
        open: true,
        message: `Successfully updated ${object}.`,
        severity: 'success',
      });

      if (selectedDomain) {
        setSelectedDomain({
          name: selectedDomain?.name,
          accessRights: selectedDomain?.accessRights,
          subdomains: selectedDomain?.subdomains,
          managers: selectedDomain?.managers,
          resolver: {
            resolverAddress:
              resolverInput === selectedDomain?.resolver?.resolverAddress
                ? selectedDomain?.resolver?.resolverAddress
                : resolverInput,
            cChainAddress:
              cAddressInput === selectedDomain?.resolver?.cChainAddress
                ? selectedDomain?.resolver?.cChainAddress
                : cAddressInput,
            xChainAddress:
              xAddressInput === selectedDomain?.resolver?.xChainAddress
                ? selectedDomain?.resolver?.xChainAddress
                : xAddressInput,
            pChainAddress:
              pAddressInput === selectedDomain?.resolver?.pChainAddress
                ? selectedDomain?.resolver?.pChainAddress
                : pAddressInput,
            publicKey:
              pubKeyInput === selectedDomain?.resolver?.publicKey
                ? selectedDomain?.resolver?.publicKey
                : pubKeyInput,
            contentHash:
              contentHashInput === selectedDomain?.resolver?.contentHash
                ? selectedDomain?.resolver?.contentHash
                : contentHashInput,
          },
        });
      }
    } catch (error) {
      if (error instanceof UserRejectedSigningError) {
        setSnackbar({
          open: true,
          message: error.message,
          severity: 'error',
        });
      } else {
        console.error(error);
        setSnackbar({
          open: true,
          message: `Failed to update ${object}.`,
          severity: 'error',
        });
      }
    }
    setCallState('idle');
  };

  const setupDefaultResolver = async () => {
    setLoadingText(`Setting up default resolver...`);
    setCallState('loading');
    try {
      await setResolver(domain.split('.'), ResolverDeployment.columbus.address);
      setSnackbar({
        open: true,
        message: `Successfully set up default resolver!`,
        severity: 'success',
      });
      setResolverInput(ResolverDeployment.columbus.address);

      if (selectedDomain) {
        setSelectedDomain({
          name: selectedDomain?.name,
          accessRights: selectedDomain?.accessRights,
          subdomains: selectedDomain?.subdomains,
          managers: selectedDomain?.managers,
          resolver: {
            resolverAddress: ResolverDeployment.columbus.address,
            cChainAddress: cAddressInput,
            xChainAddress: xAddressInput,
            pChainAddress: pAddressInput,
            publicKey: pubKeyInput,
            contentHash: contentHashInput,
          },
        });
      }
    } catch (error) {
      console.error(`Failed ot setup default resolver: ${error}`);
      setSnackbar({
        open: true,
        message: `Failed to set up default resolver.`,
        severity: 'error',
      });
    }
    setCallState('idle');
  };

  useEffect(() => {
    if (selectedDomain?.resolver) {
      setResolverInput(selectedDomain?.resolver?.resolverAddress);
      setCAddressInput(selectedDomain?.resolver?.cChainAddress);
      setPAddressInput(selectedDomain?.resolver?.pChainAddress);
      setXAddressInput(selectedDomain?.resolver?.xChainAddress);
      setPubKeyInput(selectedDomain?.resolver?.publicKey);
      setContentHashInput(selectedDomain?.resolver?.contentHash);
    }
  }, [selectedDomain]);

  return (
    <Container maxWidth="sm">
      {callState === 'loading' ? (
        <Loading text={loadingText} />
      ) : (
        <Box mt={4}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            rowSpacing={5}
            columnSpacing={2}
          >
            {selectedDomain?.resolver?.resolverAddress.length === 0 ||
            resolverInput !== ResolverDeployment.columbus.address ? (
              <Grid item xs={12} textAlign="center" mb={2}>
                <Button
                  variant="contained"
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'primary.main',
                    padding: '10px 16px',
                  }}
                  onClick={() => setupDefaultResolver()}
                >
                  Set To Default Resolver
                </Button>
              </Grid>
            ) : null}
            {inputs.map(input => (
              <React.Fragment key={input.label}>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <FormLabel
                      sx={{
                        position: 'absolute',
                        transform: 'translateY(-24px)',
                        pointerEvents: 'none',
                      }}
                    >
                      {input.label}
                    </FormLabel>
                    <TextField
                      title={
                        input.label === 'Resolver Address'
                          ? 'The address of the Resolver contract to save well-known data types'
                          : input.label === 'C-Chain Address'
                            ? `Any C-Chain address you want to associate with ${domain}`
                            : input.label === 'X-Chain Address'
                              ? `Any X-Chain address you want to associate with ${domain}`
                              : input.label === 'P-Chain Address'
                                ? `Any P-Chain address you want to associate with ${domain}`
                                : input.label === 'Public Key'
                                  ? `Any public key you want to associate with ${domain}`
                                  : input.label === 'Content Hash'
                                    ? 'For example an IPFS content hash'
                                    : ''
                      }
                      fullWidth
                      variant="outlined"
                      value={input.inputValue}
                      placeholder={`Set ${input.label}`}
                      disabled={
                        input.label !== 'Resolver Address' &&
                        selectedDomain?.resolver?.resolverAddress.length === 0
                      }
                      onChange={e => input.function(e.target.value)}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '48px',
                          '&:hover fieldset': {
                            borderColor: 'grey.600',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'grey.600',
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={
                      input.label !== 'Resolver Address' &&
                      selectedDomain?.resolver?.resolverAddress.length === 0
                    }
                    sx={{
                      color: 'text.primary',
                      backgroundColor: 'primary.main',
                      padding: '10px 16px',
                    }}
                    onClick={() =>
                      handleUpdate(input.label, input.onClick, input.args)
                    }
                  >
                    {input.prop.length > 0 ? (
                      <Typography>Update</Typography>
                    ) : (
                      <Typography>Set</Typography>
                    )}
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default ResolverSettings;
