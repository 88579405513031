import React from 'react';
import Layout from '../components/common/Layout';
import Wallet from '../pages/wallet';
import StartingApp from '../pages';
import MyDomains from '../pages/my-domains';
import { createBrowserRouter, redirect } from 'react-router-dom';
import MockTokens from 'src/pages/mock-tokens';

export const getRoutes = () => {
  const routes = [
    {
      path: '/',
      loader: () => redirect('/cns'),
    },
    {
      path: '/cns',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <StartingApp />,
        },
        {
          path: 'my-domains',
          element: <MyDomains />,
        },
      ],
    },
    {
      path: '/wallet',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Wallet />,
        },
      ],
    },
    {
      path: '/mock-tokens',
      element: <MockTokens />,
    },
  ];
  return createBrowserRouter(routes);
};
