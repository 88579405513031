import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

interface LoadingProps {
  text?: string;
}

const Loading: React.FC<LoadingProps> = ({ text }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <CircularProgress />
      {text && (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;
