import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import ShowDomains from 'src/components/category/ShowDomains';
import SelectedDomain from 'src/components/domain/SelectedDomain';
import useStore from 'src/store/cns';
import { getDomains } from 'src/utils/logs';
import { useNavigate } from 'react-router-dom';

const MyDomains = () => {
  const selectedDomain = useStore(state => state.selectedDomain);
  const domainTree = useStore(state => state.domainTree);
  const wallet = useStore(state => state.wallet);
  const setDomains = useStore(state => state.setDomains);
  const setRegisterState = useStore(state => state.setRegisterState);
  const navigate = useNavigate();

  const setFetchingNetworkData = useStore(
    state => state.setFetchingNetworkData,
  );
  const setFetchingNetworkDataText = useStore(
    state => state.setFetchingNetworkDataText,
  );

  useEffect(() => {
    setRegisterState({
      domainName: '',
      available: false,
      status: 'searching',
    });
    if (!wallet) {
      navigate('/cns');
    } else {
      const fetchData = async () => {
        setFetchingNetworkDataText('Looking up domains. Please wait...');
        setFetchingNetworkData(true);

        try {
          const newDomains = await getDomains(wallet?.address, domainTree);
          setDomains(newDomains);
        } catch (error) {
          console.error(`Failed to query logs: ${error}`);
          return null;
        }
        setFetchingNetworkData(false);
        setFetchingNetworkDataText('');
      };

      fetchData();
    }
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      {selectedDomain ? <SelectedDomain /> : <ShowDomains />}
    </Container>
  );
};

export default MyDomains;
