import React, { useState } from 'react';
import {
  Grid,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Domain, UserRejectedSigningError } from 'src/types/Common';
import { registerSubdomain } from 'src/utils/contract';
import Loading from '../widget/Loading';
import Result from '../widget/Result';
import useStore from 'src/store/cns';
import { validateInput } from 'src/utils/common';
import { insertNode } from 'src/utils/data/domains';
import { ethers } from 'ethers';

interface Props {
  parent: string;
}

const ShowSubdomains: React.FC<Props> = ({ parent }) => {
  const wallet = useStore(state => state.wallet);
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);
  const setSnackbar = useStore(state => state.setSnackbar);
  const selectedDomain = useStore(state => state.selectedDomain);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);
  const domainTree = useStore(state => state.domainTree);
  const setDomainTree = useStore(state => state.setDomainTree);

  const handleClick = (domain: Domain) => {
    setSelectedDomain(domain);
  };

  const handleEnterKey = (domain: Domain) => {
    setSelectedDomain(domain);
  };

  const handleSubdomainRegistration = async () => {
    setLoadingText(`Registering subdomain...`);
    setCallState('loading');
    const resolverAddress = selectedDomain?.resolver
      ? selectedDomain.resolver.resolverAddress
      : '';

    try {
      await registerSubdomain(parent.split('.'), searchQuery, resolverAddress);
      setSnackbar({
        open: true,
        message: `${searchQuery} successfully registered.`,
        severity: 'success',
      });

      if (selectedDomain && wallet && domainTree) {
        const mirrorDomain = selectedDomain;
        mirrorDomain.subdomains.push({
          name: searchQuery + '.' + parent,
          accessRights: 'owner',
          subdomains: [],
        });

        setDomainTree(
          insertNode(
            {
              name: searchQuery + '.' + parent,
              owner: wallet?.address,
              parentHash: ethers.namehash(parent),
              hash: ethers.namehash(searchQuery + '.' + parent),
              subdomains: [],
            },
            domainTree,
          ),
        );
        setSelectedDomain(mirrorDomain);
      }
      setSearchQuery('');
    } catch (error) {
      if (error instanceof UserRejectedSigningError) {
        setSnackbar({
          open: true,
          message: error.message,
          severity: 'error',
        });
      } else {
        console.error(error);
        setSnackbar({
          open: true,
          message: `Failed to register ${searchQuery}.`,
          severity: 'error',
        });
      }
    }

    setCallState('idle');
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredDomains =
    selectedDomain && searchQuery && selectedDomain.subdomains.length > 0
      ? selectedDomain.subdomains.filter(domain =>
          domain.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
        )
      : selectedDomain
        ? selectedDomain.subdomains
        : [];

  const determineSubdomainExistence = (): boolean => {
    if (selectedDomain && filteredDomains && filteredDomains.length >= 1) {
      for (const filteredDomain of filteredDomains) {
        const domainName = filteredDomain.name.split('.');
        if (
          selectedDomain?.name.split('.').length + 1 === domainName.length &&
          searchQuery === domainName[0]
        ) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <Box>
      {callState === 'loading' ? (
        <Loading text={loadingText} />
      ) : callState === 'idle' ? (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <TextField
                value={searchQuery}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (validateInput(event.target.value)) {
                    setSearchQuery(event.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: searchQuery ? (
                    <InputAdornment position="end">
                      <Typography variant="body1" sx={{ color: 'info.main' }}>
                        {parent}
                      </Typography>
                    </InputAdornment>
                  ) : null,
                }}
                onKeyDown={e => {
                  if (e.key === 'Tab') {
                    const matchingSuggestion = filteredDomains.find(
                      suggestion => suggestion.name.startsWith(searchQuery),
                    );
                    if (matchingSuggestion) {
                      e.preventDefault();
                      setSearchQuery(matchingSuggestion.name);
                    }
                  } else if (
                    searchQuery.length !== 0 &&
                    e.key === 'Enter' &&
                    filteredDomains.length === 1
                  ) {
                    e.preventDefault();
                    handleEnterKey(filteredDomains[0]);
                  }
                }}
                fullWidth
                placeholder="Search domain"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '46px',
                    '&:hover fieldset': {
                      borderColor: 'grey.600',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'grey.600',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              {determineSubdomainExistence() ? (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'primary.main',
                    padding: '10px 16px',
                  }}
                  onClick={() => {
                    if (searchQuery.length != 0) {
                      handleClick(filteredDomains[0]);
                    }
                  }}
                >
                  Open
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'primary.main',
                    padding: '10px 16px',
                  }}
                  onClick={handleSubdomainRegistration}
                >
                  Register
                </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {filteredDomains && filteredDomains.length > 0 ? (
              <List>
                {filteredDomains.map((domain, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderBottom:
                        filteredDomains.length > 1 &&
                        index < filteredDomains.length - 1
                          ? '1px solid #475569'
                          : 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleClick(domain)}
                  >
                    <ListItemText
                      data-cy={`sub-list-domain-name-${index}`}
                      primary={domain?.name}
                    />
                    <Typography>{domain.accessRights}</Typography>
                  </ListItem>
                ))}
              </List>
            ) : searchQuery.length === 0 ? (
              <Typography align="center" variant="h6">
                No domains registered yet
              </Typography>
            ) : (
              <Typography align="center" variant="h6">
                You do not have
                <span
                  style={{
                    color: '#66C9E8',
                    paddingLeft: '3px',
                    paddingRight: '3px',
                  }}
                >
                  {searchQuery}.{parent}
                </span>
                registered yet.
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Result text={loadingText} />
      )}
    </Box>
  );
};

export default React.memo(ShowSubdomains);
