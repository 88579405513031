import { LightMode, Wallet } from '@mui/icons-material';
import { Box, Menu, MenuItem, Select, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useStore from 'src/store/cns';

const TLNavbar = () => {
  const mode = useStore(state => state.mode);
  const toggleTheme = useStore(state => state.toggleTheme);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const options = [
    { value: 'wallet', label: 'Wallet', imgSrc: `img/camino_logo_${mode}.png` },
    {
      value: 'cns',
      label: 'Name Service',
      imgSrc: `img/camino_logo_${mode}.png`,
    },
  ];

  const [value, setValue] = React.useState(options[0].value);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    navigate(`/${event.target.value}`);
  };

  const renderSelectedItem = (selected: string) => {
    const selectedItem = options.find(option => option.value === selected);
    if (!selectedItem) return null;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={selectedItem.imgSrc}
          alt=""
          style={{ width: '35%', height: '50%', marginRight: '10px' }}
        />
        <Typography variant="h4" color="info.main">
          {selectedItem.label}
        </Typography>
      </div>
    );
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
    navigate('/cns');
  };

  const wallet = useStore(state => state.wallet);
  const logout = useStore(state => state.logout);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/wallet')) {
      setValue(options[0].value);
    } else if (location.pathname.includes('/cns')) {
      setValue(options[1].value);
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '1536px',
      }}
    >
      <Box
        sx={{
          borderRight: '1px solid',
          borderColor: 'rgba(145, 158, 171, 0.24)',
          flex: '1 1 25%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          value={value}
          onChange={handleChange}
          renderValue={renderSelectedItem}
          variant="standard"
          size="small"
          sx={{ mt: 1, width: '100%' }}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                src={option.imgSrc}
                alt=""
                style={{ width: '35%', height: '50%', marginRight: '10px' }}
              />
              <Typography variant="h4" color="info.main">
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          flex: '3 1 75%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={toggleTheme}
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LightMode
            sx={{
              mx: 0.5,
              color: 'text.primary',
            }}
          />
          <Typography color="text.primary">
            {mode === 'dark' ? 'Dark' : 'Light'}
          </Typography>
        </Button>
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: 'success.light',
              mx: 1,
            }}
          />
          <Typography color="text.primary">Columbus</Typography>
        </Box>

        <Box
          sx={{
            mx: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Wallet sx={{ mx: 0.1 }} />
          {wallet ? (
            <>
              <Typography onClick={handleMenuOpen}>
                {wallet.address.slice(0, 6)} ... {wallet.address.slice(-4)}
              </Typography>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              onClick={() => {
                navigate('/wallet');
              }}
            >
              <Typography variant="body1" color="text.primary">
                Login
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(TLNavbar);
