import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import ShowSubdomains from '../category/ShowSubdomains';
import ResolverSettings from '../category/ResolverSettings';
import RolesSettings from '../category/RolesSettings';
import DomainSettings from '../category/DomainSettings';
import DomainExpiration from '../category/DomainExpiration';
import { Chain, Domain } from 'src/types/Common';
import {
  nameExpires,
  getOwner,
  getTTL,
  getResolver,
  getCAddress,
  getAddress,
  getPubKey,
  getContentHash,
} from 'src/utils/contract';
import { getManagers, getOperatorsByOwner } from 'src/utils/logs';
import useStore from 'src/store/cns';

const SelectedDomain = () => {
  const selectedDomain = useStore(state => state.selectedDomain);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);
  const domains = useStore(state => state.domains);
  const setOperators = useStore(state => state.setOperators);

  const [expirationDate, setExpirationDate] = useState(
    new Date(0).toISOString().split('T')[0],
  );

  const [message, setMessage] = useState('');

  const calculateExpirationStatus = (
    formattedDate: string,
    domainName: string,
  ) => {
    const expiration = new Date(formattedDate);
    const now = new Date();

    // Get the difference in time (milliseconds)
    const diffInTime = expiration.getTime() - now.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    // If expiration is more than 30 days away, show nothing
    if (diffInDays > 30) {
      setMessage('');
    }
    // If expiration is within 30 days but more than 1 day away
    else if (diffInDays <= 30 && diffInDays > 1) {
      setMessage(`${domainName} will expire in ${Math.floor(diffInDays)} days`);
    }
    // If expiration is within the next 24 hours
    else if (diffInDays <= 1 && diffInDays > 0) {
      const diffInHours = Math.floor(diffInTime / (1000 * 3600));
      setMessage(`${domainName} will expire in ${diffInHours} hours`);
    }
    // If the domain expired within the last 30 days
    else if (diffInDays < 0 && diffInDays >= -30) {
      const daysSinceExpiration = Math.abs(Math.floor(diffInDays));
      if (daysSinceExpiration === 0) {
        const hoursSinceExpiration = Math.abs(
          Math.floor(diffInTime / (1000 * 3600)),
        );
        setMessage(
          `${domainName} expired, you have ${hoursSinceExpiration} hours to renew`,
        );
      } else {
        setMessage(
          `${domainName} expired, you have ${30 - daysSinceExpiration} days to renew`,
        );
      }
    }
    // If the domain expired more than 30 days ago
    else if (diffInDays < -30) {
      setMessage(`${domainName} expired`);
    }
  };

  const [owner, setOwner] = useState<string>('');
  const [ttl, setTtl] = useState<string>('0');

  useEffect(() => {
    if (selectedDomain) {
      fetchData(selectedDomain);
    }
  }, [selectedDomain]);

  const setFetchingNetworkData = useStore(
    state => state.setFetchingNetworkData,
  );
  const setFetchingNetworkDataText = useStore(
    state => state.setFetchingNetworkDataText,
  );

  const [category, setCategory] = useState(
    selectedDomain?.accessRights === 'manager' ? 'resolver' : 'subdomains',
  );

  const handleDomainSelection = async (domain: Domain) => {
    setSelectedDomain(domain);
  };

  const handleDomainJump = async (domainArray: Array<string>) => {
    const domainName = domainArray.join('.');
    if (domainArray.length === 2) {
      for (const index in domains) {
        if (domains[index].name === domainName) {
          handleDomainSelection(domains[index]);
        }
      }
    } else if (domainArray.length > 2 && selectedDomain) {
      let parentDomain = null;
      const parentName =
        domainArray[domainArray.length - 2] +
        '.' +
        domainArray[domainArray.length - 1];
      for (const index in domains) {
        if (domains[index].name === parentName) {
          parentDomain = domains[index];
        }
      }
      if (parentDomain) {
        const subdomain = findSubdomain(parentDomain.subdomains, domainName);
        if (subdomain) {
          handleDomainSelection(subdomain);
        }
      }
    }
  };

  const findSubdomain = (
    domains: Domain[],
    subdomain: string,
  ): Domain | undefined => {
    for (const index in domains) {
      if (domains[index].name === subdomain) {
        return domains[index];
      } else if (subdomain.includes(domains[index].name)) {
        return findSubdomain(domains[index].subdomains, subdomain);
      }
    }
    return undefined;
  };

  const fetchData = async (domain: Domain) => {
    const domainArr = domain.name.split('.');

    setFetchingNetworkDataText('Loading domain information. Please wait...');
    setFetchingNetworkData(true);

    // Domain Settings
    const owner = await getOwner(domainArr);
    const ttl = await getTTL(domainArr);
    setOwner(owner);
    setTtl(ttl.toString());

    // Domain Expiration
    if (domainArr.length >= 2) {
      const formattedDate = await nameExpires(domainArr[domainArr.length - 2]);
      setExpirationDate(formattedDate);
      calculateExpirationStatus(
        formattedDate,
        domainArr[domainArr.length - 2] + '.cam',
      );
    }

    // Operators
    setOperators(await getOperatorsByOwner(owner));

    // Resolver Settings
    const address = await getResolver(domainArr);

    if (selectedDomain && address.length > 0) {
      const mirrorDomain = selectedDomain;

      // Managers
      mirrorDomain.managers = await getManagers(domain.name, address);

      // Get C-Chain Address
      const cChainAddress = await getCAddress(domainArr, address);
      // Get P-Chain Address
      const pChainAddress = await getAddress(domainArr, address, Chain.P);
      // Get X-Chain Address
      const xChainAddress = await getAddress(domainArr, address, Chain.X);
      // Get Public Key
      const publicKey = await getPubKey(domainArr, address);
      // Get Content Hash
      const contentHash = await getContentHash(domainArr, address);

      mirrorDomain.resolver = {
        resolverAddress: address,
        cChainAddress,
        xChainAddress,
        pChainAddress,
        publicKey,
        contentHash,
      };
      setSelectedDomain(mirrorDomain);
    }

    setFetchingNetworkData(false);
    setFetchingNetworkDataText('');
  };
  const handleDomainRenewal = (
    expirationDate: string,
    renewalPeriod: number,
  ) => {
    const currentDate = new Date(expirationDate);
    currentDate.setFullYear(currentDate.getFullYear() + renewalPeriod);
    const newExpirationDate = currentDate.toISOString().split('T')[0];
    setExpirationDate(newExpirationDate);
  };

  const renderRightSideContent = () => {
    return (
      <>
        {selectedDomain && category === 'subdomains' && (
          <ShowSubdomains parent={selectedDomain.name} />
        )}
        {selectedDomain && category === 'resolver' && (
          <ResolverSettings domain={selectedDomain.name} />
        )}
        {selectedDomain && category === 'roles' && (
          <RolesSettings
            domain={selectedDomain.name}
            resolverAddress={
              selectedDomain?.resolver
                ? selectedDomain.resolver.resolverAddress
                : ''
            }
          />
        )}
        {selectedDomain && category === 'expiration' && (
          <DomainExpiration
            onValueChange={handleDomainRenewal}
            domain={selectedDomain}
            expirationDate={expirationDate}
          />
        )}
        {selectedDomain && category === 'settings' && (
          <DomainSettings
            owner={owner}
            ttl={ttl}
            domain={selectedDomain.name}
          />
        )}
      </>
    );
  };

  const handleListItemClick = (category: string) => {
    setCategory(category);
  };

  const renderAccessRights = () => {
    switch (selectedDomain?.accessRights) {
      case 'owner':
        return 'Owner';
      case 'operator':
        return 'Operator';
      case 'manager':
        return 'Manager';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box data-cy="selected-domain">
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={() => setSelectedDomain(undefined)}
            sx={{
              color: 'text.primary',
              '&:hover': {
                backgroundColor: 'grey.800',
                borderColor: 'grey.600',
              },
            }}
          >
            <Typography variant="body1">Return</Typography>
          </Button>
          <Typography variant="h5" data-cy="selected-domain-name-header">
            {selectedDomain?.name.split('.').map((part, index, array) => (
              <React.Fragment key={`${part}-${index}`}>
                {index < array.length - 1 ? (
                  <span
                    onClick={() => handleDomainJump(array.slice(index))}
                    style={{
                      cursor: 'pointer',
                      color: '#66C9E8',
                    }}
                  >
                    {part}
                  </span>
                ) : (
                  <span key={`${part}-${index}`}>.{part}</span>
                )}
                {index < array.length - 2 && '.'}
              </React.Fragment>
            ))}
          </Typography>

          <Typography variant="h6">{renderAccessRights()}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid',
            borderColor: 'grey.600',
            borderRadius: '8px',
            padding: 0,
            height: '60vh',
          }}
        >
          <Box
            sx={{
              width: '25%',
              borderRight: '1px solid',
              borderColor: 'grey.600',
              padding: 2,
              overflowY: 'auto',
            }}
          >
            <List>
              {(selectedDomain?.accessRights === 'owner' ||
                selectedDomain?.accessRights === 'operator') && (
                <ListItem
                  button
                  onClick={() => handleListItemClick('subdomains')}
                  sx={{ opacity: category === 'subdomains' ? 1 : 0.6 }}
                >
                  <ListItemText primary="SUBDOMAINS" />
                </ListItem>
              )}
              {(selectedDomain?.accessRights === 'owner' ||
                selectedDomain?.accessRights === 'manager') && (
                <ListItem
                  button
                  onClick={() => handleListItemClick('resolver')}
                  sx={{ opacity: category === 'resolver' ? 1 : 0.6 }}
                >
                  <ListItemText primary="RESOLVER" />
                </ListItem>
              )}
              {selectedDomain?.accessRights === 'owner' && (
                <ListItem
                  button
                  onClick={() => handleListItemClick('expiration')}
                  sx={{ opacity: category === 'expiration' ? 1 : 0.6 }}
                >
                  <ListItemText primary="EXPIRATION" />
                </ListItem>
              )}
              {(selectedDomain?.accessRights === 'owner' ||
                selectedDomain?.accessRights === 'operator') && (
                <>
                  <ListItem
                    button
                    onClick={() => handleListItemClick('roles')}
                    sx={{ opacity: category === 'roles' ? 1 : 0.6 }}
                  >
                    <ListItemText primary="ROLES" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => handleListItemClick('settings')}
                    sx={{ opacity: category === 'settings' ? 1 : 0.6 }}
                  >
                    <ListItemText primary="SETTINGS" />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
          <Box sx={{ width: '75%', padding: 2, overflowY: 'auto' }}>
            <Box sx={{ mt: 2 }}>{renderRightSideContent()}</Box>
          </Box>
        </Box>
        {message.length > 0 ? (
          <Typography
            textAlign="center"
            mt={2}
            color="error.main"
            variant="body1"
          >
            {message}
          </Typography>
        ) : null}
      </Container>
    </Box>
  );
};

export default SelectedDomain;
