import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useStore from 'src/store/cns';
import SearchBar from '../widget/SearchBar';
import { validateInput } from 'src/utils/common';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Navbar = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const setRegisterState = useStore(state => state.setRegisterState);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate('/cns');
    } else if (newValue === 1) {
      navigate('/cns/my-domains');
    } else if (newValue === 2) {
      navigate('/mock-tokens');
    }
  };
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const openDomain = (domain: string) => {
    setRegisterState({
      domainName: domain,
      available: false,
      status: 'open',
    });
    navigate('/cns');
  };

  useEffect(() => {
    if (location.pathname === '/cns') {
      setValue(0);
    } else if (location.pathname === '/cns/my-domains') {
      setValue(1);
    } else if (location.pathname === '/mock-tokens') {
      setValue(2);
    }
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '1536px',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        sx={{
          '& .MuiTabs-indicator': { display: 'none' },
          height: '61px',
          '& .Mui-selected': {
            color: `${theme.palette.text.primary} !important`,
          },
          marginTop: '0.5rem',
        }}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
      >
        <Tab
          className="tab"
          disableRipple
          label="Search Domain"
          {...a11yProps(0)}
          key={0}
          sx={{
            '&::after': { display: value === 0 ? 'block' : 'none' },
            color: value === 0 ? 'text.primary' : 'text.secondary',
          }}
        />
        {isLoggedIn ? (
          <Tab
            className="tab"
            disableRipple
            label="My Domains"
            {...a11yProps(1)}
            key={2}
            sx={{
              '&::after': { display: value === 2 ? 'block' : 'none' },
              color: value === 1 ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : null}
        {isLoggedIn ? (
          <Tab
            className="tab"
            disableRipple
            label="Mint Tokens"
            {...a11yProps(1)}
            key={3}
            sx={{
              '&::after': { display: value === 2 ? 'block' : 'none' },
              color: value === 2 ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : null}
      </Tabs>

      <Box mt={1.25} width="25%">
        <SearchBar
          data-cy="sd-searchbar"
          hint="Search domain"
          validateInput={validateInput}
          openDomain={openDomain}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Navbar);
