import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import useStore from 'src/store/cns';
import { validateInput } from 'src/utils/common';
import { useNavigate } from 'react-router-dom';

const SearchDomain = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const registerState = useStore(state => state.registerState);
  const registeredDomains = useStore(state => state.registeredDomains);
  const setRegisterState = useStore(state => state.setRegisterState);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>(
    registerState.domainName,
  );
  const [callState, setCallState] = useState<
    'idle' | 'available' | 'taken' | 'too_short'
  >('idle');

  const handleInputChange = (event: any) => {
    const input = event.target.value;
    if (validateInput(input)) {
      setSearchQuery(input);
      if (input.length > 0 && input.length <= 3) {
        setCallState('too_short');
      } else if (
        registeredDomains.find(domain => domain.name.split('.')[0] === input)
      ) {
        setCallState('taken');
      } else if (input.length === 0) {
        setCallState('idle');
      } else {
        setCallState('available');
      }
    }
  };

  const openDomain = () => {
    setRegisterState({
      domainName: searchQuery + '.cam',
      available: true,
      status: 'open',
    });
  };

  const handleClick = () => {
    setRegisterState({
      domainName: searchQuery,
      available: true,
      status: 'registering',
    });
  };

  const renderButtons = () => {
    if (isLoggedIn && searchQuery.length !== 0 && callState === 'available') {
      return (
        <Button
          variant="contained"
          onClick={() => handleClick()}
          sx={{
            color: 'text.primary',
            backgroundColor: 'primary.main',
            padding: '10px 16px',
          }}
        >
          Register
        </Button>
      );
    } else if (searchQuery.length !== 0) {
      return (
        <Button
          variant="contained"
          onClick={() => {
            setRegisterState({
              status: 'registering',
              available: true,
              domainName: searchQuery,
            }),
              navigate('/wallet');
          }}
          sx={{
            color: 'text.primary',
            backgroundColor: 'primary.main',
            padding: '10px 16px',
          }}
        >
          Connect Wallet
        </Button>
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 20 }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          data-cy="sd-title"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Search Your Camino Domain
        </Typography>
        <Typography
          data-cy="sd-subtitle"
          variant="h4"
          align="center"
          color="text.primary"
          sx={{ mb: 2 }}
        >
          Linking Identities to Wallets: Unifying Names, Information & Entities
        </Typography>
        <Box mt={3} width="45%">
          <TextField
            fullWidth
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <Typography variant="body1" sx={{ color: 'info.main' }}>
                    .cam
                  </Typography>
                </InputAdornment>
              ) : null,
            }}
            placeholder="Search .cam domain"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '48px',
                '&:hover fieldset': {
                  borderColor: 'grey.600',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.600',
                },
              },
            }}
            onChange={handleInputChange}
          />
        </Box>
        {callState === 'available' ? (
          <Box mt={4}>{renderButtons()}</Box>
        ) : callState === 'taken' ? (
          <Typography mt={4} data-cy="sd-domain-taken" color="error.main">
            Domain Taken
            <Button
              onClick={openDomain}
              sx={{
                color: 'text.primary',
                '&:hover': {
                  backgroundColor: 'grey.800',
                  borderColor: 'grey.600',
                },
              }}
            >
              <Typography variant="body1">Open</Typography>
            </Button>
          </Typography>
        ) : callState === 'too_short' ? (
          <Typography mt={4} data-cy="sd-invalid-name" color="error.main">
            Must have more than 3 characters
          </Typography>
        ) : null}
      </Box>
    </Container>
  );
};

export default React.memo(SearchDomain);
