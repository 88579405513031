import { ethers } from 'ethers';
import TokenAbi from '../contracts/abis/Token.json';
import { Token, DomainTree } from 'src/types/Common';
import EC from 'elliptic/lib/elliptic/ec';
import { bech32 } from 'bech32';
import { Buffer } from 'buffer';

export const labelToId = (label: string): any => {
  const labelBytes = ethers.toUtf8Bytes(label);
  const labelHash = ethers.keccak256(labelBytes);
  const id = ethers.toBigInt(labelHash);
  return id;
};

export const getTokenDetails = async (
  tokenAddress: string,
  basePrice: bigint,
  provider: ethers.Provider,
): Promise<Token> => {
  if (tokenAddress === '0x0000000000000000000000000000000000000000') {
    return {
      tokenAddress: tokenAddress,
      basePrice: Number(basePrice.toString().slice(0, -18)),
      name: 'Camino Token',
      symbol: 'CAM',
    };
  }
  const tokenContract = new ethers.Contract(tokenAddress, TokenAbi, provider);

  const name = await tokenContract.name();
  const symbol = await tokenContract.symbol();

  const token: Token = {
    tokenAddress: tokenAddress,
    basePrice: Number(basePrice.toString().slice(0, -18)),
    name: name,
    symbol: symbol,
  };

  return token;
};

export const getDomainHash = (parent: string, label: string): string => {
  const labelhash = ethers.keccak256(ethers.toUtf8Bytes(label));

  const abiCoder = ethers.AbiCoder.defaultAbiCoder();

  const domainHash = ethers.keccak256(
    abiCoder.encode(['bytes32', 'bytes32'], [parent, labelhash]),
  );

  return domainHash;
};

export const validateInput = (input: string): boolean => {
  // Allow empty input (deleting all characters)
  if (input === '') return true;

  // Regular expression to match the allowed characters and structure
  const regex = /^(?!-)(?!.*--)[a-z0-9-]+$/;

  // Test the input against the regular expression
  return regex.test(input);
};

export const getLevel1Subdomains = (
  domain: DomainTree,
): { name: string; owner: string }[] => {
  if (!domain.subdomains || domain.subdomains.length === 0) {
    return [];
  }

  return domain.subdomains.map(subdomain => ({
    name: subdomain.name,
    owner: subdomain.owner,
  }));
};

function addressToString(
  hrp: string,
  chainID: string,
  addressBuffer: Buffer,
): string {
  return `${chainID}-${bech32.encode(hrp, bech32.toWords(addressBuffer))}`;
}

function addressFromPublicKey(pubk: Buffer): Buffer {
  const ec = new EC('secp256k1');
  let publicKey = pubk;

  if (publicKey.length === 65) {
    const key = ec.keyFromPublic(pubk);
    const compressedPubKey = key.getPublic(true, 'hex');
    publicKey = Buffer.from(String(compressedPubKey), 'hex');
  }

  if (publicKey.length === 33) {
    const sha256 = Buffer.from(ethers.sha256(publicKey).slice(2), 'hex');
    const ripesha = Buffer.from(ethers.ripemd160(sha256).slice(2), 'hex');

    return ripesha;
  }

  throw new Error('Unable to make address.');
}

function bufferToType(
  vb: Buffer,
  type: string,
  hrp: string,
  chainID: string,
): string | undefined {
  if (type === 'bech32') {
    return addressToString(hrp, chainID, vb);
  }
  return undefined;
}

export const deriveAddressesFromPubKey = (
  pubKey: string,
): { xChainAddress: string; pChainAddress: string; cChainAddress: string } => {
  const pubKeyBuffer = Buffer.from(pubKey, 'hex');

  const address = addressFromPublicKey(pubKeyBuffer);

  const xChainAddress = bufferToType(address, 'bech32', 'columbus', 'X');
  const pChainAddress = bufferToType(address, 'bech32', 'columbus', 'P');

  const cChainAddress = ethers.computeAddress('0x' + pubKey);

  return {
    xChainAddress: xChainAddress ? xChainAddress : '',
    pChainAddress: pChainAddress ? pChainAddress : '',
    cChainAddress,
  };
};
