import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

interface RegisterDomainProps {
  timePeriod: number;
  handleIncrementYear: () => void;
  handleDecrementYear: () => void;
}

const TimePeriod: React.FC<RegisterDomainProps> = ({
  timePeriod,
  handleIncrementYear,
  handleDecrementYear,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Button
        data-cy="decrement-year"
        onClick={handleDecrementYear}
        sx={{ minWidth: '30px', color: '#fff' }}
      >
        <ArrowLeft data-cy="arrow-left" />
      </Button>
      <Typography
        data-cy="show-time-period"
        variant="body1"
        color="text.primary"
      >
        {timePeriod}
        {timePeriod > 1 ? ' years' : ' year'}
      </Typography>
      <Button
        data-cy="increment-year"
        onClick={handleIncrementYear}
        sx={{ minWidth: '30px', color: '#fff' }}
      >
        <ArrowRight data-cy="arrow-right" />
      </Button>
    </Box>
  );
};

export default TimePeriod;
