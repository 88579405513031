import { Outlet, useLocation } from 'react-router-dom';

import { Box, Toolbar } from '@mui/material';
import React from 'react';
import Navbar from './Navbar';
import TLNavbar from './TLNavbar';
import '../suite-override.css';
import useStore from 'src/store/cns';
import CustomAlert from '../widget/CustomAlert';
import FetchingNetworkData from '../widget/FetchingNetworkData';
import SignTransaction from '../widget/SignTransaction';

const Layout = () => {
  const location = useLocation();

  const fetchingNetworkData = useStore(state => state.fetchingNetworkData);
  const fetchingNetworkDataText = useStore(
    state => state.fetchingNetworkDataText,
  );
  const snackbar = useStore(state => state.snackbar);
  const setSnackbar = useStore(state => state.setSnackbar);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: theme => theme.palette.background.paper,
      }}
    >
      <Toolbar
        sx={{
          borderBottom: '1px solid',
          borderColor: 'rgba(145, 158, 171, 0.24)',
          background: theme => theme.palette.background.paper,
          flexGrow: 1,
          p: '1.5rem',
          zIndex: 9,
          position: 'fixed',
          top: '0px',
          width: '100vw',
          height: '61px',
          display: 'flex',
          justifyContent: 'center',
          right: 0,
        }}
      >
        <TLNavbar />
      </Toolbar>

      <Toolbar
        sx={{
          borderBottom: '1px solid',
          borderColor: 'rgba(145, 158, 171, 0.24)',
          background: theme => theme.palette.background.paper,
          flexGrow: 1,
          p: '1.5rem',
          zIndex: 9,
          position: 'fixed',
          top: '65px',
          width: '100vw',
          height: '61px',
          display: 'flex',
          justifyContent: 'center',
          right: 0,
        }}
      >
        {location.pathname.includes('cns') ? <Navbar /> : null}
      </Toolbar>

      <Box
        sx={{
          marginTop: '8rem',
          width: '100%',
          maxWidth: '1530px',
          height: '100%',
          paddingBottom: '4rem',
        }}
      >
        <Outlet />
      </Box>
      <CustomAlert
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
      <FetchingNetworkData
        text={fetchingNetworkDataText}
        open={fetchingNetworkData}
      />
      <SignTransaction />
    </Box>
  );
};
export default React.memo(Layout);
