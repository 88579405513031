import React from 'react';
import {
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from '@mui/material';
import useStore from 'src/store/cns';
import { Token } from 'src/types/Common';

interface RegisterDomainProps {
  onValueChange: (token: Token) => void;
}

const TokenSelection: React.FC<RegisterDomainProps> = ({ onValueChange }) => {
  const selectedToken = useStore(state => state.selectedToken);
  const setSelectedToken = useStore(state => state.setSelectedToken);
  const tokens = useStore(state => state.tokens);

  const handleChange = (event: any) => {
    const token = tokens.find(
      token => token.tokenAddress === event.target.value,
    );
    if (token) {
      setSelectedToken(token);
      onValueChange(token);
    }
  };

  return (
    <FormControl
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '46px',
          '&:hover fieldset': {
            borderColor: 'grey.600',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'grey.600',
          },
        },
        textAlign: 'start',
      }}
    >
      <InputLabel id="payment-token-label">Payment Token</InputLabel>
      <Select
        labelId="payment-token-label"
        id="payment-token-select"
        data-cy="payment-token-select"
        value={selectedToken ? selectedToken.tokenAddress : ''}
        onChange={handleChange}
        label="Payment Token"
      >
        {tokens.map(token => (
          <MenuItem
            key={token.tokenAddress}
            value={token.tokenAddress}
            data-cy={`payment-token-option-${token.tokenAddress}`}
          >
            <Typography variant="body1" color="text.primary">
              {token.name} ({token.symbol})
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TokenSelection;
