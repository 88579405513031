import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import './index.css';
import CaminoTheme from './theme';
import { getRoutes } from './routes';
import useStore from './store/cns';

const container = document.getElementById('app');

if (!container) {
  throw new Error(
    "Element with id 'app' not found. Application cannot be initialized.",
  );
}

const root = createRoot(container);

const App = () => {
  const mode = useStore(state => state.mode);
  const theme = createTheme(CaminoTheme.getThemeOptions(mode));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={getRoutes()} />
    </ThemeProvider>
  );
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
