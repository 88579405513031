import React from 'react';
import { Typography, Backdrop, Paper, Button, Box } from '@mui/material';
import { useSignerStore } from 'src/store';

const SignTransaction = () => {
  const { handleSign, handleReject, isSigning, functionName, txCost } =
    useSignerStore();

  return (
    <Backdrop
      data-cy="sign"
      open={isSigning}
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
        }}
      >
        <Typography variant="h3">Sign Transaction</Typography>

        <Box my={5}>
          <Typography textAlign="center">
            You are about to approve a transaction to execute the function{' '}
            <b>{functionName}</b>, <br />
            which will incur an estimated transaction cost of <b>
              {txCost}
            </b>{' '}
            CAM.
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              color: 'text.primary',
              backgroundColor: 'primary.main',
              padding: '10px 16px',
              mr: 2,
            }}
            onClick={handleSign}
          >
            Sign
          </Button>

          <Button
            onClick={handleReject}
            sx={{
              color: 'text.primary',
              '&:hover': {
                backgroundColor: 'grey.800',
                borderColor: 'grey.600',
              },
            }}
          >
            <Typography variant="body1">Reject</Typography>
          </Button>
        </Box>
      </Paper>
    </Backdrop>
  );
};

export default SignTransaction;
