import { ethers } from 'ethers';
import RegistrarDeployment from '../../contracts/deployments/Registrar.json';
import TokenABI from '../../contracts/abis/Token.json';
import { getProvider, getSigner } from '../wallet';
import { estimateTxCost } from '../contract';
import { getState } from 'src/store';

export const approveAllowance = async (
  allowance: number,
  tokenAddress: string,
): Promise<number> => {
  const wallet = getState().wallet;

  const txCost = await estimateTxCost(tokenAddress, TokenABI, 'approve', [
    wallet?.address,
    allowance,
  ]);
  const signer = await getSigner(txCost, 'approve');

  const token = new ethers.Contract(tokenAddress, TokenABI, signer);
  await token.approve(
    RegistrarDeployment.columbus.address,
    ethers.parseEther(allowance.toString()),
  );

  if (signer) {
    return checkAllowance(
      await signer?.getAddress(),
      RegistrarDeployment.columbus.address,
      tokenAddress,
    );
  } else {
    return 0;
  }
};

export const checkAllowance = async (
  ownerAddress: string,
  spenderAddress: string,
  tokenAddress: string,
): Promise<number> => {
  const token = new ethers.Contract(tokenAddress, TokenABI, getProvider());

  const allowance = await token.allowance(ownerAddress, spenderAddress);

  const allowanceInEther = ethers.formatEther(allowance);

  return Number(allowanceInEther);
};
