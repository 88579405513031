import { DomainTree } from 'src/types/Common';

export const insertNode = (
  node: DomainTree,
  domainTree: DomainTree,
): DomainTree => {
  if (node.parentHash === domainTree.hash) {
    domainTree.subdomains.push(node);
    return domainTree;
  }

  for (const subdomain of domainTree.subdomains) {
    const updatedSubdomain = insertNode(node, subdomain);

    if (updatedSubdomain !== subdomain) {
      return domainTree;
    }
  }

  return domainTree;
};
