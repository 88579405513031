import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface CustomAlertProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  message,
  severity,
  onClose,
}) => {
  const getIcon = (severity: AlertColor) => {
    if (severity === 'success') {
      return (
        <CheckCircleIcon fontSize="inherit" style={{ color: '#18B728' }} />
      );
    }
    if (severity === 'error') {
      return <ErrorIcon fontSize="inherit" style={{ color: '#E5431F' }} />;
    }
    return null;
  };

  const getStyles = (severity: AlertColor) => {
    if (severity === 'success') {
      return {
        backgroundColor: '#E8F5E9',
        color: '#18B728',
        border: '1px solid #C8E6C9',
      };
    }
    if (severity === 'error') {
      return {
        backgroundColor: '#FFEBEE',
        color: '#E5431F',
        border: '1px solid #FFCDD2',
      };
    }
    return {};
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ top: '72px' }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        icon={getIcon(severity)}
        style={{
          ...getStyles(severity),
          borderRadius: '8px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
