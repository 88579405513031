export enum Chain {
  X,
  P,
  C,
}

export interface RegisterState {
  domainName: string;
  available: boolean;
  status: 'success' | 'error' | 'registering' | 'searching' | 'open';
}

export interface Token {
  tokenAddress: string;
  basePrice: number;
  name: string;
  symbol: string;
}

export interface Domain {
  name: string;
  accessRights: string;
  subdomains: Domain[];
  resolver?: ResolverData;
  managers?: string[];
}

export type ContractCall = 'idle' | 'loading' | 'success' | 'error';

interface ResolverData {
  resolverAddress: string;
  cChainAddress: string;
  xChainAddress: string;
  pChainAddress: string;
  publicKey: string;
  contentHash: string;
}

export type DomainTree = {
  name: string;
  owner: string;
  parentHash: string;
  hash: string;
  subdomains: DomainTree[];
};

export interface SignerState {
  isSigning: boolean;
  txCost: string;
  functionName: string;
  approveSigner: (txCost: string, functionName: string) => Promise<boolean>;
  resolvePromise: ((value: boolean) => void) | null;
  handleSign: () => void;
  handleReject: () => void;
}

export class UserRejectedSigningError extends Error {
  constructor(message = 'User rejected signing the transaction') {
    super(message);
    this.name = 'UserRejectedSigningError';
  }
}
