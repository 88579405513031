import React, { useState } from 'react';
import {
  TextField,
  Box,
  Autocomplete,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useStore from 'src/store/cns';

interface SearchBarProps {
  hint: string;
  onSearch?: (query: string) => void;
  onEnterPress?: (query: string) => void;
  validateInput?: (input: string) => boolean;
  openDomain?: (domain: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  hint,
  onSearch,
  onEnterPress,
  validateInput,
  openDomain,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const registeredDomains = useStore(state => state.registeredDomains);

  const handleInputChange = (newInputValue: string) => {
    if (!validateInput || validateInput(newInputValue)) {
      setInputValue(newInputValue);
      if (onSearch) {
        onSearch(newInputValue);
      }
    }
  };

  const handleSelection = (event: any, value: any) => {
    const inputArray = value?.split('.');
    let domain = '';
    if (inputArray?.length === 2) {
      domain = value;
    } else if (value && registeredDomains.length > 0) {
      for (const index in registeredDomains) {
        if (registeredDomains[index].name.split('.')[0] === value) {
          domain = registeredDomains[index].name;
        }
      }
    }
    if (domain.length > 0 && openDomain) {
      openDomain(domain);
      setInputValue('');
    } else if (onEnterPress) {
      onEnterPress(inputValue);
    }
  };

  const filteredDomains =
    inputValue && registeredDomains.length > 0
      ? registeredDomains.filter(domain =>
          domain.name.toLowerCase().startsWith(inputValue.toLowerCase()),
        )
      : [];

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
      data-cy="sd-searchbar"
    >
      <Autocomplete
        freeSolo
        fullWidth
        options={filteredDomains.map(suggestion => suggestion.name)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        onChange={handleSelection}
        onClick={() => console.log('click')}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: inputValue ? (
                <InputAdornment position="end">
                  <Typography variant="body1" sx={{ color: 'info.main' }}>
                    .cam
                  </Typography>
                </InputAdornment>
              ) : null,
            }}
            placeholder={hint}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '48px',
                '&:hover fieldset': {
                  borderColor: 'grey.600',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.600',
                },
              },
            }}
          />
        )}
        onKeyDown={e => {
          if (e.key === 'Tab') {
            const matchingSuggestion = filteredDomains.find(suggestion =>
              suggestion.name.startsWith(inputValue),
            );
            if (matchingSuggestion) {
              e.preventDefault();
              setInputValue(matchingSuggestion.name);
            }
          } else if (
            inputValue.length !== 0 &&
            e.key === 'Enter' &&
            filteredDomains.length === 1
          ) {
            e.preventDefault();
          }
        }}
      />
    </Box>
  );
};

export default SearchBar;
