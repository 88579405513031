import { ethers } from 'ethers';
import { getState } from '../store/cns';
import { useSignerStore } from '../store/cns';
import { UserRejectedSigningError } from 'src/types/Common';

export const getProvider = (): ethers.Provider => {
  return new ethers.JsonRpcProvider(
    'https://columbus.camino.network/ext/bc/C/rpc',
    {
      chainId: 501,
      name: 'columbus',
    },
  );
};

export const getSigner = async (
  txCost: string,
  functionName: string,
): Promise<ethers.Signer | null> => {
  const { approveSigner } = useSignerStore.getState();

  const signerAccepted = await approveSigner(txCost, functionName);

  if (signerAccepted) {
    const wallet = getState().wallet;
    const provider = getProvider();
    return wallet ? wallet.connect(provider) : null;
  } else {
    throw new UserRejectedSigningError();
  }
};
